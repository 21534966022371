import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { Waypoint } from 'react-waypoint';

import {handleWaypointIn} from "../utilities/waypoint"
import Layout from "../components/layout/Layout"
import Lightbox from "../components/lightbox/Lightbox"
import ContactSection from "../components/contact/ContactSection"
import SEO from "../components/seo"



export default function Template({
	data, // this prop will be injected by the GraphQL query below.
	pageContext
}) {
	const { markdownRemark } = data // data.markdownRemark holds your post data
	const { frontmatter, html } = markdownRemark
	const { next, prev } = pageContext
	const title = `Portfolio: ${frontmatter.title}`
	return (
		
		<div className="portfolio-detail">
				<Layout>         		
					<SEO 
						title={title}
						description={`Chris Bartlett: Web Development Portfolio: ${frontmatter.blurb}`}
					/>	  					 
					<section className="portfolio-header">
						<div className="title">
							<Waypoint onEnter={() => handleWaypointIn('title-text', 'focus-in-expand')}>         
								<div id="title-text">             
									<h4>{frontmatter.title}</h4>
								</div>    
							</Waypoint>   						
							
							<Waypoint onEnter={() => handleWaypointIn('main-title-text', 'focus-in-expand')}>         
								<h1 id="main-title-text">{frontmatter.blurb}</h1>  
							</Waypoint>   							
							
						</div>
						<div className="thumb-wrapper">
							{frontmatter.thumbnail && 
								<div className="portfolio-thumb-wrapper">								
									<div className="portfolio-image">
										<Waypoint onEnter={() => handleWaypointIn('main-image', 'jello-horizontal')}>         
											<div id="main-image">             
												<Img 
													fixed={frontmatter.thumbnail.childImageSharp.fixed} 
													alt={frontmatter.title} 
													key="header-thumb" 
													objectFit="cover"
													objectPosition="top center"																						
												/>
											</div>    
										</Waypoint>   									
									</div>
								</div>
							}
						</div>					
					</section> 

					<section className="portfolio-back">
						<div className="back-to">
							<Link to="/portfolio"><span className="icon-arrow_left1"></span> Back to Portfolio</Link>
						</div>				
					</section>	
					<section className="portfolio-details">
					<Waypoint onEnter={() => handleWaypointIn('main-text')}>         
						<div id="main-text" className="main-content">             
							<div  dangerouslySetInnerHTML={{ __html: html }} />
						</div>    
					</Waypoint>   					
						
						<aside>
							<div className="results">
									<div className="result">
										<div className="result-icon">
										<span className="icon-clients-consulting2"></span>
									</div>								
									<div className="result-header">
										<h6>Client:</h6>
										<p>{frontmatter.title}</p> 
									</div>

								</div>

								{frontmatter.website && 
								<div className="result">
									<div className="result-icon">
										<span className="icon-adaptive-coding"></span> 
									</div>									
									<div className="result-header">								
										<h6>Website:</h6> 
										<p><a href={frontmatter.website} target="_blank" rel="noreferrer noopener">Visit website</a></p>
									</div>
																		
								</div>
								}

								<div className="result">
									<div className="result-icon">
										<span className="icon-seo-audit"></span> 
									</div>									
									<div className="result-header">
										<h6>Type:</h6> 
										<p>{frontmatter.type}</p>
									</div>									
								</div>	
								
								<div className="result">
									<div className="result-icon">
										<span className="icon-source-code"></span> 
									</div>									
									<div className="result-header">
										<h6>Software:</h6> 
										<p>{frontmatter.software}</p>
									</div>									
								</div>		
								
								<div className="result">
									<div className="result-icon">
										<span className="icon-red-or-dev-developer"></span> 
									</div>									
									<div className="result-header">
										<h6>Development:</h6> 
										<p>{frontmatter.development}</p>
									</div>									
								</div>		
								
								<div className="result">
									<div className="result-icon">
										<span className="icon-website-design"></span> 
									</div>									
									<div className="result-header">
										<h6>Theme:</h6> 
										<p>{frontmatter.theme}</p>
									</div>									
								</div>		

								<div className="result">
									<div className="result-icon">
										<span className="icon-bussiness"></span>
									</div>									
									<div className="result-header">
										<h6>Credits:</h6>  
										<p>{frontmatter.credits}</p>
									</div>									
								</div>	

								<div className="result">
									<div className="result-icon">
										<span className="icon-launch"></span>
									</div>									
									<div className="result-header">
										<h6>Launch:</h6>  
										<p>{frontmatter.launch}</p>
									</div>									
								</div>	

								<nav>
									<div className="previous">
										{prev && 
											<Link to={`/portfolio${prev.fields.slug}`}><span className="icon-arrow_left1"></span> Previous</Link>
										}
									</div>									
									<div className="next">
										{next && 
											<Link to={`/portfolio${next.fields.slug}`}>Next <span className="icon-arrow_right1"></span></Link>
										}   
									</div>									
								</nav>									
															
							</div>
						</aside>
					</section>

       

						

						<section className="portfolio-detail-content">
						<Waypoint onEnter={() => handleWaypointIn('screenshots-text')}>         
							<div id="screenshots-text">             
								<h2>Screenshots from the site</h2>
							</div>    
						</Waypoint>   

							

							{frontmatter.images && 
									<div className="portfolio-images">
										
										{frontmatter.images.map((image, index) => (      											
												<Lightbox 
													fullSize={image.full.childImageSharp.fluid} 
													thumbSize={image.full.childImageSharp.fixed} 
													alt={image.alt} 
													key={index}
													index={index}
													small={image.small}
												/>    																															
										))}									
									</div>
							}


						</section>
						<ContactSection/>  
				</Layout>
		</div>
		

	)
}

export const pageQuery = graphql`
	query portfolioBySlug($slug: String!) {
		site {
			siteMetadata {
				title
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title      
				post_type
				filters
				blurb
				website
				development
				theme
				software
				launch
				type
				credits
				thumbnail {
					childImageSharp {
						fixed(width: 1000, height: 1000, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}						
					}
				}
				images {           
					full {
						childImageSharp {
							fixed(width: 600, height: 600, quality: 90) {
								...GatsbyImageSharpFixed_withWebp
							}
							fluid(maxWidth: 1000, quality: 90) {
								...GatsbyImageSharpFluid
							}															
						}
					}   
					alt 
					small         
				}    
			}
		} 
	}
`