import React from "react"
import Img from "gatsby-image"
import { Waypoint } from 'react-waypoint';

import {handleWaypointIn} from "../../utilities/waypoint"

const Lightbox = ({ fullSize, thumbSize, alt, index, small}) => {

    const openLightbox = (e, divId) => {
        e.preventDefault()
  
        if (document.querySelector('body.no-scroll') === null) {
            document.querySelector('body').classList.add('no-scroll')
        }     
  
        document.getElementById(divId).classList.add('active');
    }
  
    const closeLightbox = (e, divId) => {
        e.preventDefault()
  
        if (document.querySelector('body.no-scroll') !== null) {
            document.querySelector('body').classList.remove('no-scroll')
        }     
  
        document.getElementById(divId).classList.remove('active');
    }  

    const mobile = small === true ? ' small' : ''

    return (
        <div className="lightbox-group">

        <Waypoint onEnter={() => handleWaypointIn(`button-text`+index, 'scale-in-ver-bottom')}>         
            <div id={`button-text`+index}>             
                <button href="#" onClick={(e) => { openLightbox(e, `lightbox`+index) }} tabIndex={0}>
                    <div className="img-wrapper">
                        <Img 
                            fixed={thumbSize} 
                            alt={alt} 
                            key={index} 
                            objectFit="cover"
                            objectPosition="top center"
                        />                             
                    </div>             
                </button>
            </div>    
        </Waypoint>   
    
    

            <div className="lightbox" id={`lightbox`+index}>
                <button href="#" tabIndex={0} onClick={(e) => { closeLightbox(e, `lightbox`+index) }} role="link"><span className="icon-close"></span></button>  
                <h4>{alt}</h4>

                <div className={`lightbox-wrapper${mobile}`}>
                    <Img 
                        fluid={fullSize} 
                        alt={alt} 
                        key={index} 
                        objectFit="cover"
                        objectPosition="top center"
                    />  
                </div>
                 
            </div>   
        </div>        
    )
}
  
export default Lightbox